import styled from 'styled-components';

export const MainContainer = styled.div`
  width: 774px;
  margin: 0 auto;
  padding: 30px 0;
  font-family: 'Figtree-Regular', arial, sans-serif;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0.02em;
  @media (max-width: 991px) {
    width: 100%;
    padding: 30px;
  }
`;

export const HeaderH1 = styled.h1`
  text-align: center;
  margin: 0 0 30px 0;
  padding: 0;
  font-family: 'Figtree-Bold', arial, sans-serif;
  font-weight: normal !important;
  color: #6e2c8b;
  font-size: 22px;
  line-height: 30px;
  letter-spacing: 0px;
`;

export const ParaText = styled.p`
  margin: 0 0 30px 0;
  padding: 0;
  font-family: 'Figtree-Regular', arial, sans-serif;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #444;
  b {
    font-family: 'Figtree-Bold', arial, sans-serif;
  }
`;

export const LinkStyle = styled.a`
  text-decoration: none;
  color: #c77dec;
  :hover {
    text-decoration: none;
    color: #661988;
  }
`;
